import React, { useEffect } from 'react';

import { Link,useHistory } from 'react-router-dom';
import ImageR from '../assets/img/Icons/Icons/image.png'
import prev from '../assets/img/Icons/Icons/prev.png';
import ImageQ from '../assets/img/Icons/Icons/imageQ.png'
import ImageC from '../assets/img/Icons/Icons/imagechat.png'
import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import Sidebar from './Sidebar';
import ImgcardHero from '../assets/img/Icons/Icons/bagde-hero.png'
import Footer from './footer';
import {Url} from './url';

const CoupensDetail = (props) => {

    const history  = useHistory();
    const short_link = Url();
    console.log("my second data is ",props.location.value);


    useEffect(()=>{
        if(!history.location.state)
            history.push('/coupens');
        console.log("hello world");
        console.log("props is ",props)
        console.log("history is ",history);
        console.log("our data is ",history.location.state?history.location.state.PLUCode:"");
        console.log("our state is ",state);
    },[])

    const [state,setState] = React.useState(history.location.state?!history.location.state.IsInCart ? "Add To List" : "Remove From List":"");
    

    function remove_to_list()
    {
        fetch(`${short_link}/removetolist`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                data : history.location.state.SSNewsId,
                token:localStorage.getItem("Shopping_token"),
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log("data.message is ",data.message);
            if(data.message === 1){
                console.log("data is removed from the cart");
                setState("Add To List");
            }else
                console.log("err is arise in remove from the cart")
        })
        .catch((err)=>{
            console.log("err is coming ");
            
        })
    }

    function add_to_list()
    {
        console.log("add_to_list() is now executed ...");
        fetch(`${short_link}/addtolist`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                data:history.location.state,
                token:localStorage.getItem("Shopping_token"),
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log("data.message.ErrorMessage.ErrorCode is ",data.message.ErrorMessage.ErrorCode);
            if(data.message.ErrorMessage.ErrorCode === 1)
            {
                console.log("cart is saved successfully");
                setState("Remove From List")
            }
            else
            {
                console.log("cart is not saved successfully");
            }
        })
        .catch(err=>{
            console.log("err is coming in add_to_list() please check ");
        });
    }

    function check_the_user()
    {
        if(!localStorage.getItem("Shopping_token"))
        {
            history.push('/Signin2');
        }else{}
    }

    return (
        <div style={{background:"#343a40"}}>
            {check_the_user()}
            <div className="bg-white border shadow  bg-bottom-round" style={{overflowY:"scroll"}}>
                <div className="container page17">
                    <div className="row mt-3">
                        {
                            props.location.value === "search" ? <div onClick={()=>{history.push({pathname:`/search`,value:props.location.name})}} className="col-sm-1-my17 text-center ">
                                                                    <img src={prev} width="44%"/>
                                                                </div>
                                                            :
                            props.location.value === "departmentdata" ? <div onClick={()=>{history.push({pathname:`/Coupons/${history.location.id}`,state:props.location.name})}} className="col-sm-1-my17 text-center ">
                                                                            <img src={prev} width="44%"/>
                                                                        </div>
                                                                      : <div onClick={()=>{history.push({pathname:'/Coupens',value:props.location.value})}} className="col-sm-1-my17 text-center ">
                                                                            <img src={prev} width="44%"/>
                                                                        </div>
                        }
                        {/* <div onClick={()=>{history.push({pathname:'/Coupens',value:props.location.value})}} className="col-sm-1-my17 text-center ">
                            <img src={prev} width="44%"/>
                        </div> */}
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Coupon Details</h5>
                        </div>

                    </div>

                    <div className="row page17-p">
                        <div className="col-sm-12 py-2 ">
                            <hr className="mt-2 mb-0" />
                            <div className="row">
                                <div className="col-sm-12 px-0">
                                    <div>
                                        <center><img style={{width:"auto"}} src={history.location.state?history.location.state.ImagePath:""} alt="" className="img-bagde-hero"/></center>
                                    </div>
                                    <div className="bg-red bagde-top-hero product-bagde-hero">
                                        <h4 className="text-white p-2 text-center">{history.location.state?history.location.state.PLUCode==="100%"?"Free":`Save ${history.location.state.PLUCode}`:""}</h4>
                                    </div>
                                </div>
                                <div className="col-sm-12 bottom-btm-font mt-2 text-center">
                                    {
                                        state==='Add To List'?<button onClick={add_to_list} type="button" className="btn btn-success">Add to List</button>
                                                            :<button onClick={remove_to_list} type="button" className="btn btn-danger">Remove from List</button>
                                    }
                                    {/* <button onClick={()=>{
                                        if(state==='Add To List')
                                            add_to_list();
                                        else
                                            remove_to_list();
                                        }} type="button" class="btn btn-success mt-3 py-2 " style={{width:"70%"}}>{state}</button> */}
                                    <hr />
                                </div>
                            </div>
                            <div className="row  scroll-inner3 ">
                                {/* <div className="col-sm-12 bottom-btm-font mt-2 text-center">
                                    <button onClick={()=>{
                                        if(state==='Add To List')
                                            add_to_list();
                                        else
                                            remove_to_list();
                                        }} type="button" class="btn btn-success mt-3 py-2 " style={{width:"70%"}}>{state}</button>
                                    <hr />
                                </div> */}
                                <div className="col-sm-12 mt-4">
                                    <h5>{history.location.state?history.location.state.ProductName:""}</h5>
                                    <h5>Save {history.location.state?history.location.state.PLUCode:""}</h5>
                                    <p className="mb-0"><small className="text-secondary-light-gray " style={{ fontSize: "14px",color:"black" }}>{history.location.state?history.location.state.Details.substr(0,120):""}</small></p>
                                </div>
                                {/* <div className="col-sm-12 bottom-btm-font mt-2 text-center">
                                    <button onClick={()=>{
                                        if(state==='Add To List')
                                            add_to_list();
                                        else
                                            remove_to_list();
                                        }} type="button" class="btn btn-success mt-3 py-2 " style={{width:"70%"}}>{state}</button>
                                    <hr />
                                </div> */}
                                <div className="col-sm-12">
                                    <p className="mb-0 text-center"><small className="text-dark fw500" style={{ fontSize: "18px" }}>Disclaimer</small></p>
                                    <p className="mt-3 mb-3 "><small  style={{ fontSize: "15px",color:"grey" }}>{history.location.state?history.location.state.Details:""}</small></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default CoupensDetail;

