import React from "react";
import M from 'materialize-css';
import Sidebar from './Sidebar'//import $ from "jquery";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MainImg from '../assets/img/reset15.png'
import prev from '../assets/img/Icons/Icons/prev.png';

const FAQ = ()=>{
    return (
        <div>
            <div className="bg-white border shadow  bg-bottom-round" style={{overflow:"auto",height:"100vh",borderRadius:"0px"}}>
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/ContactUs">                       
                                <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">FAQ</h5>
                        </div>
                    </div>

                    <div style={{marginTop:"70%"}}>
                        <p className="text-center">NO CONTENT TO DISPLAY</p>
                    </div>
                </div>
            </div>
        </div>
    // <p style={{width:"100%",textAlign:"center",marginTop:"40%"}}>No Content to display</p>
    )
}

export default FAQ;