import React, { useEffect } from 'react';

import Sidebar from './Sidebar'//import $ from "jquery";

import { Link } from 'react-router-dom';
import MainImg from '../assets/img/reset15.png'
import prev from '../assets/img/Icons/Icons/prev.png';

const EmailPin = () => {


    return (
        <div>
            <div className="bg-white border shadow  bg-bottom-round" style={{overflow:"auto",height:"100vh",borderRadius:"0px"}}>
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/Setnewpin">
                            <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Set New PIN</h5>
                        </div>
                    </div>

                    <div style={{margin:"10%"}}>
                        <center><img src={MainImg} style={{height:"29vh"}} /></center>
                    </div>
                </div>
            </div>

            <div className="  p-4 mt-4" style={{position:"absolute",bottom:"10px", borderRadius: "25px 25px 0 0", boxShadow: "0 -0.3rem 1rem rgba(0,0,0,.15)" }}>
                <div className="row ">
                    <div className="col-sm-12">
                        <p className="text-center bg-light-st pin2-sm">Your PIN will be sent to your email address associated with your account. Kindly enter the email address and click on Send PIN.</p>
                        <div className="">
                            <form className="reset-form mt-5 ">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Enter Phone Number </label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
                                </div>
                                <Link to="/Signin2" type="button" className="btn btn-success w-100 fw100 mt-4 py-2">Send PIN</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default EmailPin;