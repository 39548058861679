import React, { useEffect } from 'react';
import Footer from '../pages/footer'
import Sidebar from './Sidebar';
import prev from '../assets/img/Icons/Icons/prev.png';
import { useHistory } from 'react-router-dom';
import { Url } from './url';

const Privacypolicy = ()=>{
    const history = useHistory();
    const short_link = Url();

    const [url,seturl] = React.useState("");

    useEffect(()=>{
        // fetch('/getlink',{
        fetch(`${short_link}/getlink`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                clientappname: "Clay County PW",
                clientid: "203",
                storegroupid: 1,
                storeid: 1,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("data is ",data);
            console.log(data.message.SocialMediaSettings[4].Value);
            seturl(data.message.SocialMediaSettings[3].Value)
            console.log(data.message.SocialMediaSettings[3].Value);
        })
        .catch(err=>{
            console.log("error is arise and the error is ",err);
        })
    },[])



    console.log("history is ",history)
    return(
        <div>
            <div className="bg-white border shadow  bg-bottom-round">
                <div className="container page17">
                    <div className="row mt-3">

                        {
                            history.location.name === "contactus" ? 
                                                                    <div onClick={()=>{history.push('/ContactUs')}} className="col-sm-1-my17 text-center ">
                                                                        <img src={prev} width="44%"/>
                                                                    </div>
                                                                  : 
                                                                    <div onClick={()=>{history.push('/')}} className="col-sm-1-my17 text-center ">
                                                                        <img src={prev} width="44%"/>
                                                                    </div>
                        }

                        

                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Privacy Policy</h5>
                        </div>
                    </div>

                    <div style={{}}>
                        <iframe src={url} style={{width:"100%",height:"81vh"}} title="Iframe Example"></iframe>
                    </div>
                </div>
            </div>
            <Footer />            
        </div>
    )
}

export default Privacypolicy;