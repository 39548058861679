import React,{useEffect,useState} from "react";
import {Link,useHistory} from "react-router-dom";
import prev from '../assets/img/Icons/Icons/prev.png';
import MainImgSmall from '../assets/img/Icons/Icons/smallimgicon.png'
import LPin from '../assets/img/Icons/Icons/Lpin.png'
import RedL from '../assets/img/Icons/Icons/sm-barcode.png'
import Imgcard1 from '../assets/img/Icons/Icons/cameraI.jpg'
import Cardskeleton2 from '../pages/Skeleton2';
import Footer from '../pages/footer'
import {Url} from './url'

const Searchresult = ()=>{
    
    const history = useHistory();
    const short_link = Url();

    const [searchvalue,setsearchvalue] = useState("");
    const [searcheddata,setsearcheddata] = useState("");

    useEffect(()=>{

        if(!history.location.value)
            history.push('/coupens');

        if(!localStorage.getItem("Shopping_token"))
            history.push('/')
            
        let a = history.location.value;
        let b;

        if(a!==undefined)
        b = a.charCodeAt(0);
        
        let token = localStorage.getItem("Shopping_token");
        let searchcode;
        let searchvalue;
        let flag = 0;

        if(b>=48 && b<=57){
            console.log("it's a number");
            flag = 1;
        }
        else{
            console.log("it's a word");    
            flag = 2;
        }

        if(flag===1)
            searchcode = "U";
        else
            searchcode = "N";

        searchvalue = a;

        console.log("the searchcode is ",searchcode);
        console.log("the searchvalue is ",searchvalue);

    //    fetch(`${short_link}/search`,{
          fetch(`${short_link}/searchcoupen`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                searchcode:searchcode,
                searchvalue:searchvalue,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("the searched data is ",data);
            console.log("the searched item is ",data.message.Specials)
            setsearcheddata(data);
        })
        .catch(err=>{
            console.log("the error is arise and the error is ",err);
        })

    },[])    

    function add_to_list(item)
    {
        return new Promise((resolve,reject)=>{
            // fetch(`${short_link}/addtolist`,{
            fetch(`${short_link}/addtolist`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                data:item,
                token:localStorage.getItem("Shopping_token"),
                subdomain:localStorage.getItem("subdomain")
            })
            }).then(res=>res.json())
            .then(data=>{
                console.log(data)
                resolve(data)
            })
            .catch(err=>{
                console.log(err);
                reject(err);
            })
        })
    }

    function remove_to_list(item)
    {
        return new Promise((resolve,reject)=>{
            fetch(`${short_link}/removetolist`,{
            // fetch('/removetolist',{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                data:item.SSNewsId,
                token:localStorage.getItem("Shopping_token"),
                subdomain:localStorage.getItem("subdomain")
            })
            }).then(res=>res.json())
            .then(data=>{
                console.log(data)
                resolve(data)
            })
            .catch(err=>{
                console.log(err);
                reject(err);
            })
        })
    }

    function republished()
    {
        let a = history.location.value;
        let b;

        if(a!==undefined)
        b = a.charCodeAt(0);
        
        let token = localStorage.getItem("Shopping_token");
        let searchcode;
        let searchvalue;
        let flag = 0;

        if(b>=48 && b<=57){
            console.log("it's a number");
            flag = 1;
        }
        else{
            console.log("it's a word");    
            flag = 2;
        }

        if(flag===1)
            searchcode = "U";
        else
            searchcode = "N";

        searchvalue = a;

        console.log("the searchcode is ",searchcode);
        console.log("the searchvalue is ",searchvalue);

        fetch(`${short_link}/searchcoupen`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                searchcode:searchcode,
                searchvalue:searchvalue,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("the searched data is ",data);
            setsearcheddata(data);
        })
        .catch(err=>{
            console.log("the error is arise and the error is ",err);
        })

    }

    function searchthedata()
    {
        let len = searchvalue;
        
        if(len === undefined)
            window.alert("please enter more than 2 character");
        else if(len.length >=3)
            history.push({pathname:"/search",value:searchvalue})
        else
            window.alert("please enter more than 2 character");
    }

    function givetheanswer()
    {
        console.log(searcheddata)
    }

    // function findthedata(item)
    // {
    //     console.log(item);
    //     console.log(item.ProductImage);
    //     var mydeals = JSON.parse(localStorage.getItem("mydelas"));
    //     var allcoupons = JSON.parse(localStorage.getItem("allcoupons"))
    //     console.log("length of mydeals is",mydeals.length);
    //     console.log("length of all coupens is ",allcoupons.length)
    //     console.log("mydeals[0] image is",mydeals[0].ImagePath);

    //     for(let i=0;i<mydeals.length;i++)
    //     {
    //         if(item.ProductId === mydeals[i].SSNewsId)
    //         {
    //             console.log("the data is matched in mydeals ",mydeals[i])
    //         }
    //     }

    //     for(let i=0;i<allcoupons.length;i++)
    //     {
    //         if(item.ProductId === allcoupons[i].SSNewsId)
    //         {
    //             console.log("the data is matched in all coupens ",allcoupons[i])
    //         }
    //     }

    //     console.log("mydeals is ",mydeals);
    //     console.log("all coupons is ",allcoupons);
    // }

    return (
        <div>
            <div className="bg-white border shadow  bg-bottom-round" style={{overflow:"auto"}}>
                <div className="container page17">
                    
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/Coupens">
                                <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        
                        <div className="col-sm-9-my17-C text-center ">
                            <img src={MainImgSmall} alt="" style={{objectFit:"cover"}} height={30} width={40} className="ml-5" />
                        </div>

                        <div className="col-sm-3-my17-C text-center d-none ">
                            <a className="text-dark fw500" data-toggle="modal" data-target="#exampleModal1" > <img src={LPin} alt="" height={20} width={14} className="mr-1" /><span className="d-none" style={{ fontSize: "12px" }}>Storepin</span></a>
                        </div>
                    </div>
                    
                    <div className="row page17-p">
                        <div className="col-sm-12">
                            <hr className="mt-2" />

                            <div style={{display:"none"}} className="row px-4">
                                <div className="input-group px-2 mb-1 shadow" style={{flexWrap:"nowrap"}}>
                                    <input value={searchvalue} onChange={(e)=>{setsearchvalue(e.target.value)}}  type="text" className="form-control" placeholder="Enter keyword to search" aria-describedby="basic-addon2"/>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text bg-white border-0">
                                            <img src={RedL} alt="" style={{objectFit:"cover"}} style={{ width: "30px" }} /><i  onClick={()=>{searchthedata()}} className="fa fa-search pr-1 pl-2" style={{ fontSize: "18px" }} aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div onClick={()=>{givetheanswer()}}><center><h5>Result</h5></center></div>

                            {/* --------------------------------------------------------------------- */}
                            <div className="tab-pane" id="tabs-2" role="tabpanel">
                                <div className="row px-3 scroll-inner4" style={{height:"69vh"}}>

                                    <div className="col-sm-12 mt-2 mb-1">

                                        {/* --------------------------------------------- */}

                                        {
                                            !searcheddata ? <>
                                                                <Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 />
                                                                <Cardskeleton2 />
                                                                <Cardskeleton2 />
                                                            </>
                                                        :
                                                            searcheddata.message.Specials === null || searcheddata.message.Specials.length===0 ? <h4>NO Result Found</h4>
                                                            :
                                                            searcheddata.message.Specials.map((item,i)=>{
                                                                return(
                                                                    <div  className=" card-hori-t" key={i}>
                                                                        <div className="row" style={{borderRadius:"9px",marginBottom:"2px"}}>
                    
                                                                            <div className="col-sm-5-my-C px-0" onClick={()=>{history.push({pathname:"/CoupensDetail", state:item,value:"search",name:history.location.value})}}>

                                                                                <div className="reward-card-img-outer-left-C reward-card-img-outer-left">
                                                                                    <img src={item.ImagePath} alt="" className="reward-card-img-left" />
                                                                                    
                                                                                    {/* <p className="bg-danger coupen-middle-card-bagde"><small className="text-white pl-2"> 1.00 $</small></p> */}
                                                                                    
                                                                                </div>

                                                                            </div>
                    
                                                                            <div className="col-sm-7-my-C bg-light card-img-right " style={{fontSize:"16px",position:"relative",top:"19px"}}>
                                                                                <div style={{padding:"1px",paddingLeft:"11px"}}>
                                                                                    <div onClick={()=>{history.push({pathname:"/CoupensDetail", state:item,value:"search",name:history.location.value})}}>
                                                                                        {/* <p className="float-right mb-0"><small className="text-danger">6 Days Left</small></p> */}
                                                                                        <br />
                                                                                        <p className=" List-card-img-right-p1 mt-1 mb-1" style={{color:"black"}}>{item.ProductName}</p>
                                                                                        <p className="mb-0 List-card-img-right-p2 text-secondary-light-gray" >{item.Details.substr(0,30)}...</p>
                                                                                    </div>
                                                                                    {
                                                                                        item.IsInCart===false ? <button onClick={()=>{
                                                                                                console.log("hello");
                                                                                                add_to_list(item).then(data=>{                                         //the function is fully correct
                                                                                                    console.log("data i received from add_to_list is ",data);         // the details which i get in searched is not enough so that
                                                                                                    if(data.message.ErrorMessage.ErrorCode === 1)                     // indirectly some data is missing to call addtolist API
                                                                                                    {
                                                                                                        console.log("now republished () is going to call");
                                                                                                        republished();
                                                                                                    }
                                                                                                })
                                                                                        }} style={{borderRadius:"0.4rem"}} type="button" className="btn btn-success w-100 mt-3">Add To List</button>
                                                                                                      : <button onClick={()=>{
                                                                                                        console.log("bye");
                                                                                                        remove_to_list(item).then(data=>{
                                                                                                            console.log("data.message is ",data.message);
                                                                                                            if(data.message === 1)
                                                                                                            {
                                                                                                                republished('mydeals')
                                                                                                            }
                                                                                                        })

                                                                                        }} style={{borderRadius:"0.4rem"}} type="button" className="btn btn-danger w-100 mt-3">Remove from list</button>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                                })
                                        }
                                        {/* <div className=" card-hori-t">
                                            <div className="row" style={{borderRadius:"9px",marginBottom:"2px"}}>

                                                <div className="col-sm-5-my-C px-0">
                                                    <div className="reward-card-img-outer-left-C reward-card-img-outer-left">
                                                        <img src={Imgcard1} alt="" className="reward-card-img-left" />
                                                        <div>
                                                            <p className="bg-danger coupen-middle-card-bagde"><small className="text-white pl-2"> 1.00 $</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-7-my-C bg-light card-img-right " style={{fontSize:"16px",position:"relative",top:"19px"}}>
                                                    <div style={{padding:"1px",paddingLeft:"11px"}}>
                                                        
                                                        <div >
                                                            <p className="float-right mb-0"><small className="text-danger">6 Days Left</small></p>
                                                            <br />
                                                            <p className=" List-card-img-right-p1 mt-1 mb-1" style={{color:"black"}}>Lorem 10...</p>
                                                            <p className="mb-0 List-card-img-right-p2 text-secondary-light-gray" >this is a small details...</p>
                                                        </div>
                                                        <button style={{borderRadius:"0.4rem"}} type="button" className="btn btn-danger w-100 mt-3">Danger</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* ------------------------------------------------------ */}


                                    </div> 
                                   

                                </div>
                            </div>
                            {/* ----------------------------------------------------------------------- */}
                        </div>
                    </div>

                </div>
            </div>
            <Footer  name="coupon"/>
        </div>
    )
}

export default Searchresult;