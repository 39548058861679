import React, { useEffect } from 'react';
//import $ from "jquery";

import { Link } from 'react-router-dom';
import MainImg from '../assets/img/signin1.png'
import Icon1 from '../assets/img/Icons/Icons/Icon-01.png'

import Icon2 from '../assets/img/Icons/Icons/Icon-06.png'

import Icon3 from '../assets/img/Icons/Icons/Icon-02.png'
import Icon4 from '../assets/img/Icons/Icons/Icon-07.png'

import Icon5 from '../assets/img/Icons/Icons/Icon-03.png'
import Icon6 from '../assets/img/Icons/Icons/Icon-08.png'


import Icon7 from '../assets/img/Icons/Icons/Icon-04.png'
import Icon8 from '../assets/img/Icons/Icons/Icon-09.png'

import Icon9 from '../assets/img/Icons/Icons/Icon-05.png'
import Icon10 from '../assets/img/Icons/Icons/Icon-10.png'

import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import Footer from './footer';
const SandP = () => {


    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])


    return (
        <div>
            <div className="bg-white border shadow  bg-bottom-round">
                <div className="container page17 px-3">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <p className="mb-0"><Link to="/" className="text-decoration-none"><img src={Barside} className="Barside" alt="" /></Link></p>
                        </div>
                        <div className="col-sm-11-my17 text-center ">

                            <h5 className=" signin1-h1-top  mb-0">Support and Privacy</h5>
                        </div>

                    </div>

                    <div className="row page17-p">
                        <div className="col-sm-12 py-2">
                            <hr className="mt-2" />
                            <p>You have a range of controls to manage your privacy across Google's services. To find answers to many common questions about privacy and your data in Google's products and services, select an option below or visit our <a href="">Privacy Policy</a>.</p>
                            <p className="mt-4">Note: This article doesn't address technical problems or issues unrelated to data privacy. If you have technical issues, visit <a href="">our help center and support forums</a></p>
                        </div>
                    </div>
                </div>

            </div>
   <Footer/>
        </div>
    )
}

export default SandP;

