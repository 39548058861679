import React, { useEffect,useState } from 'react';
import M from 'materialize-css';
import { useHistory } from 'react-router-dom';
//import $ from "jquery";
import prev from '../assets/img/Icons/Icons/prev.png';
import { Link } from 'react-router-dom';
import MainImg from '../assets/img/signin2.png';
import {Url} from './url';


const Register1 = () => {
    const short_link = Url();
    const history = useHistory();
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [phonenumber,setPhonenumber] = useState('');
    const [zipcode,setZipcode] = useState('');
    const [preferredstore,setPreferredstore] = useState('');
    const [email,setEmail] = useState('');
    const [pin,setPin] = useState('');
    const [cpin,setCpin] = useState('');
    const [state,setState] = useState(false);
    const [storename,setStoreName] = useState();
    var flag = 0;
    localStorage.setItem("subdomain", "claycountypw")                                                      //this variable helps not to make dublicate Users  

    useEffect(()=>{
        if(localStorage.getItem("dfn"))                               //dummy first name
            setFirstname(localStorage.getItem("dfn"));
        if(localStorage.getItem("dln"))                                //dummy last name
            setLastname(localStorage.getItem("dln"));
        if(localStorage.getItem("dpn"))                                     //dummy phone number
            setPhonenumber(localStorage.getItem("dpn"));
        if(localStorage.getItem("dzc"))                                     //dummy zip code
            setZipcode(localStorage.getItem("dzc"));
        if(localStorage.getItem("de"))                                  // dummy email
            setEmail(localStorage.getItem("de"));
    },[])

    useEffect(()=>{
        fetch(`${short_link}/getclientstore`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                subdomain:localStorage.getItem("subdomain")
                })
        }).then(res=>res.json())
        .then(data=>{
            console.log("data i get is ",data);
             setStoreName(data);
        });
    },[])

    function setData(e)
    {
        if(state === false)
        {
            M.toast({html: 'Please Accept Terms and Condition',classes:"#c62828 red darken-3"})
            return;
        }
        if(pin!==cpin)
        {
            M.toast({html: 'Confirm Pin is not Matching',classes:"#c62828 red darken-3"})
            return;
        }
        if(!firstname || !lastname || !zipcode || !pin || !cpin || !preferredstore || preferredstore === 'Prefered Stores' || !email)
        {
            if(!firstname)
            {
                M.toast({html: 'FirstName is missing .',classes:"#c62828 red darken-3"});
                return;
            }
            else if(!lastname)
            {
                M.toast({html: 'LastName is missing .',classes:"#c62828 red darken-3"});
                return;
            }
            else if(!zipcode)
            {
                M.toast({html: 'Zipcode is missing .',classes:"#c62828 red darken-3"});
                return;
            }
            else if(!pin)
            {
                M.toast({html: 'pin is missing .',classes:"#c62828 red darken-3"});
                return;
            }
            else if(!cpin)
            {
                M.toast({html: 'cpin is missing .',classes:"#c62828 red darken-3"});
                return;
            }
            else if(!preferredstore || preferredstore === 'Prefered Stores')
            {
                M.toast({html: 'preferredstore is missing .',classes:"#c62828 red darken-3"});
                return;
            }
            else
            {
                M.toast({html: 'email is missing .',classes:"#c62828 red darken-3"});
                return;
            }
        }
        if(phonenumber.length !== 10)
        {
            console.log(firstname.length);
            M.toast({html: 'Enter a valid mobile number',classes:"#c62828 red darken-3"})
            return;
        }

        if(zipcode.length !== 5)
        {
            console.log(firstname.length);
            M.toast({html: 'Enter a 5 digit Zip Code',classes:"#c62828 red darken-3"})
            return;
        }
    
        if(pin.length !== 4)
        {
            M.toast({html: 'Enter a 4 digit PIN',classes:"#c62828 red darken-3"})
            return;
        }

        if(flag === 0)
        {
            flag = 1;  //Now This part of code is not executed more than one time  

            fetch(`${short_link}/storeid`,{
                //fetch('/storeid',{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    name:preferredstore,
                    subdomain:localStorage.getItem("subdomain")
                    })
                }).then(result=>result.json())
            .then(data2=>{
                console.log("data is ",data2);
                console.log("id is ",data2.message[0].ClientStoreId)
        
                fetch(`${short_link}/Register1`, {
                    //fetch("/Register1", {
                    method:"post",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        firstname,lastname,phonenumber,zipcode,preferredstore,email,pin,
                        storeid:data2.message[0].ClientStoreId, subdomain:localStorage.getItem("subdomain")
                    })
                }).then(data=>data.json())
            .then(res=>{
                console.log(res);
                console.log(res.message);
                
                if(res.message === 1)
                {
                    M.toast({html:"User Registered successfull, Please login now",classes:"#43a047 green darken-1"});                
                    localStorage.removeItem("dfn");
                    localStorage.removeItem("dln");
                    localStorage.removeItem("dpn");
                    localStorage.removeItem("dzc");
                    localStorage.removeItem("de");
                    history.push('/Signin2');
                }
                else if(res.message === -1)
                {
                    flag = 0;
                    M.toast({html:"User Already Exist",classes:"#c62828 red darken-3"});
                    return;
                }
                else{
                    flag = 0;
                    M.toast({html:"Something Went Wrong",classes:"#c62828 red darken-3"});
                    return;
                }
            }).catch(err=>{
                M.toast({html: 'Something is wrong ...',classes:"#c62828 red darken-3"})
                console.log("error is arise in setData() the error is ",err);
                flag = 0;
            })
        })                     

        }                   // end of If command
    }

    function move()
    {
        history.push("/Signin2");
    }

    function fun()
    {
        console.log(state);
    }

    function adddummydata()
    {
        localStorage.setItem("dfn",firstname);
        localStorage.setItem("dln",lastname);
        localStorage.setItem("dpn",phonenumber);
        localStorage.setItem("dzc",zipcode);
        localStorage.setItem("de",email);
    }

    return (
        <div>
            <div className="container">
                <div className="row mt-3">
                   
                    <div className="col-sm-1-my text-center" style={{width:"11%"}}>
                        <Link to="/" className="text-decoration-none">
                        {/* <p><Link to="/Signin2" className="text-decoration-none"><i style={{margin:"-5px"}} className="fa fa-angle-left fa-angle-left-reg" aria-hidden="true">
                        </i></Link></p> */}
                        <img src={prev} width="44%"/>
                        </Link>
                    </div>
                    
                    <div className="col-sm-11-my text-center " style={{width:"71%"}}>

                        <h3 className="font-weight-bold signin1-h1  ">Register</h3>
                    </div>

                </div>
            </div>
            <div className="  p-4 mt-2" style={{ borderRadius: "25px 25px 0 0", boxShadow: "0 -0.3rem 1rem rgba(0,0,0,.15)" }}>
                <div className="row ">
                    <div className="col-sm-12">
                        <div className="">
                            <form className="signin2-form">

                                <div className="row">
                                    <div className="col-sm-6 col">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">First Name </label>
                                            <input style={{color:'black'}} type="text" onChange={(e)=>{setFirstname(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" value={firstname}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col">

                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Last Name </label>
                                            <input style={{color:'black'}} type="text" value={lastname} onChange={(e)=>{setLastname(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name" />
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Phone Number </label>
                                            <input style={{color:'black'}} type="text" value={phonenumber} onChange={(e)=>{setPhonenumber(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone Number" />
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Zip Code </label>
                                            <input style={{color:'black'}} type="text" value={zipcode} onChange={(e)=>{setZipcode(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Zip Code" />
                                        </div>

                                    </div>
                                </div>




                                <div className="form-group">
                                    <label for="exampleInputEmail1">Prefered Store </label>
                                    <select style={{color:'black'}} className="form-control" id="exampleFormControlSelect1" onChange={(e)=>{setPreferredstore(e.target.value)}}>
                                        <option default selected>Prefered Stores</option>
                                        {storename ?    <>
                                                        
                                                        {
                                                            
                                                            storename.message.map((item,i)=>{
                                                                return (<option style={{width:"100%"}} key={i}>{item}</option>)
                                                            })
                                                        }      
                                                        </>
                                                        :
                                                        <>
                                                        <option default selected>Prefered Stores</option>
                                                        </>
                                        }  
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label for="exampleInputEmail1">Email Address</label>
                                    <input style={{color:'black'}} type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email Address" />
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">PIN </label>
                                            <input style={{color:'black'}} type="password" value={pin} onChange={(e)=>{setPin(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="4 Digit PIN" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Confirm PIN </label>
                                            <input style={{color:'black'}} type="password" value={cpin} onChange={(e)=>{setCpin(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Confirm PIN" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                    <div className="form-check px-3 mt-3">
                                            
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" style={{opacity:"1"}}  value={state} onChange={(e)=>{setState(e.target.checked)}}/>
                                            <label onClick={fun} className="form-check-label font-weight-bold " style={{opacity:"0.8"}} for="exampleCheck1">Agree to our
                                            <Link onClick={adddummydata} to="/Termsandcondition" className="text-red text-decoration-none"> Term's </Link>& <Link onClick={adddummydata} to="/Termsandcondition" className="text-red text-decoration-none">Conditions </Link>and
                                            <Link onClick={adddummydata} to="/Privacypolicy" className="text-red text-decoration-none"> Privacy Policy</Link>.</label>
                                    </div>
                         
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-12 bottom-btm-font mt-2">
                        <button type="button" className="btn btn-success w-100 mt-3" onClick={setData} >Register</button>
                        <button type="button" className="btn btn-outline-danger w-100 mt-3" onClick={move}>Cancel</button>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default Register1;