import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import Sidebar from './Sidebar'//import $ from "jquery";

import { Link, useHistory } from 'react-router-dom';
import Footer from '../pages/footer'
import MainImg from '../assets/img/reset15.png'
import prev from '../assets/img/Icons/Icons/prev.png';

const Resetpin = () => {

    const history = useHistory();
    const [pin,setpin] = useState("");
    const [cpin,setcpin] = useState("");

    function handledata()
    {
        var pin = document.getElementById("exampleInputEmail2");
        var cpin = document.getElementById("exampleInputEmail3");
        var currentpin = document.getElementById("exampleInputEmail1");
        

        if(!currentpin.value)
        {
            M.toast({html: 'Please enter Current PIN ',classes:"#c62828 red darken-3"});
            return;
        }

        if(!pin.value)
        {
            M.toast({html: 'Please enter PIN',classes:"#c62828 red darken-3"});
            return;
        }

        if(!cpin.value )
        {
            M.toast({html: 'Please enter Confirm PIN',classes:"#c62828 red darken-3"});
            return;
        }

        if(pin.value !== cpin.value)
        {
            M.toast({html: 'PIN are not matching',classes:"#c62828 red darken-3"})
            return;
        }
        else
        {
            M.toast({html: "successfully PIN updated",classes:"#43a047 green darken-1"});
            history.push("/Coupens")
        }
    }

    return (
        <div style={{backgroundColor:"rgb(52, 58, 64)"}}>
            <div className="bg-white border shadow  bg-bottom-round" style={{overflow:"auto"}} >
                
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/Profile">
                            <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Change PIN</h5>
                        </div>
                    </div>

                    <div style={{margin:"10%"}}>
                        <center><img src={MainImg} style={{height:"29vh"}} /></center>
                    </div>
                </div>

                <div className="  p-4 mt-4" style={{ borderRadius: "25px 25px 0 0",marginRight:"0px", boxShadow: "0 -0.3rem 1rem rgba(0,0,0,.15)" }}>
                    <div className="row ">
                        <div className="col-sm-12">
                            <div className="">
                                <form className="reset-form mt-5 ">
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Current PIN*</label>
                                        <input type="password" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Pin" required/>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">New PIN*</label>
                                        <input type="password" className="form-control" id="exampleInputEmail2" aria-describedby="emailHelp" placeholder="Pin" required/>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Confirm PIN* </label>
                                        <input type="password" className="form-control" id="exampleInputEmail3" aria-describedby="emailHelp" placeholder="Confirm Pin" required/>
                                    </div>
                                    <button onClick={()=>{handledata()}} type="button" className="btn btn-success w-100 fw100 mt-4 py-2">Reset Pin</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            
            </div>
            <Footer name="profile"/>
        </div>
    )
}

export default Resetpin;