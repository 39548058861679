import React, { useEffect } from 'react';

import $ from 'jquery';
import { Link,useHistory } from 'react-router-dom';
import Sideprofile from '../assets/img/Icons/Icons/sideprofile-img.png'
import Icon1 from '../assets/img/Icons/Icons/Icon-11.png'

import Icon2 from '../assets/img/Icons/Icons/Icon-12.png'
import Icon3 from '../assets/img/Icons/Icons/Icon-15.png'
import Icon4 from '../assets/img/Icons/Icons/Icon-13.png'
import Icon5 from '../assets/img/Icons/Icons/Icon-14.png'
import Icon6 from '../assets/img/Icons/Icons/Icon-16.png'
import Icon7 from '../assets/img/Icons/Icons/msg1.png'
import Icon8 from '../assets/img/Icons/Icons/SL.png'
import Icon9 from '../assets/img/Icons/Icons/card.png'
import Icon10 from '../assets/img/Icons/Icons/imageC10.png';
import Barcodes from '../assets/img/Icons/Icons/checkoutbarcode.png'
import Dummy from '../assets/img/dummy.png'
import MainImgSmall from '../assets/img/Icons/Icons/smallimgicon.png'
// import Barside from '../assets/img/Icons/Icons/Icon-17.png'

import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import Footer from './footer';
import { Url } from './url';
const Sidebar = () => {
    const short_link = Url();
    const history = useHistory();
    const [active,setActive] = React.useState("");
    const [barcode,setbarcode] = React.useState(localStorage.getItem("barcode")?localStorage.getItem("barcode"):undefined);

    // useEffect(()=>{                                                     //DONT REMOVE THIS COMMENT                        //DONT REMOVE THIS COMMENT            //\\DONT REMOVE THIS COMMENT        
    //     if(!localStorage.getItem("barcode"))                             //DONT REMOVE THIS COMMENT                      //DONT REMOVE THIS COMMENT            //  \\DONT REMOVE THIS COMMENT  
    //     {                                                                 //DONT REMOVE THIS COMMENT                    //DONT REMOVE THIS COMMENT            //    \\DONT REMOVE THIS COMMENT
    //         fetch(`${short_link}/card`,{     //DONT REMOVE THIS COMMENT                  //DONT REMOVE THIS COMMENT            //      \\DONT REMOVE THIS COMMENT
    //         method:"get",                                                   //DONT REMOVE THIS COMMENT                //DONT REMOVE THIS COMMENT            //        \\DONT REMOVE THIS COMMENT
    //         headers:{                                                        //DONT REMOVE THIS COMMENT              //DONT REMOVE THIS COMMENT            //          \\DONT REMOVE THIS COMMENT
    //             "Content-Type":"application/json"                             //DONT REMOVE THIS COMMENT            //DONT REMOVE THIS COMMENT            //            \\DONT REMOVE THIS COMMENT
    //         }                                                                  //DONT REMOVE THIS COMMENT          //DONT REMOVE THIS COMMENT            //              \\DONT REMOVE THIS COMMENT
    //     }).then(res=>res.json())                                                //DONT REMOVE THIS COMMENT        //DONT REMOVE THIS COMMENT            //                \\DONT REMOVE THIS COMMENT
    //     .then(data=>{                                                            //DONT REMOVE THIS COMMENT      //DONT REMOVE THIS COMMENT
    //         console.log(data);                                                   //DONT REMOVE THIS COMMENT      \\DONT REMOVE THIS COMMENT
    //         console.log("barcode is ",data.message.BarCodeUrl);                 //DONT REMOVE THIS COMMENT        \\DONT REMOVE THIS COMMENT
    //         localStorage.setItem("barcode",data.message.BarCodeUrl);           //DONT REMOVE THIS COMMENT          \\DONT REMOVE THIS COMMENT
    //         setbarcode(data.message.BarCodeUrl)                               //DONT REMOVE THIS COMMENT            \\DONT REMOVE THIS COMMENT
    //     })                                                                   //DONT REMOVE THIS COMMENT              \\DONT REMOVE THIS COMMENT
    //     .catch(err=>console.log("err is arise in card"))                    //DONT REMOVE THIS COMMENT                \\DONT REMOVE THIS COMMENT
    //     }                                                                  //DONT REMOVE THIS COMMENT                  \\DONT REMOVE THIS COMMENT
    // },[])                                                                 //DONT REMOVE THIS COMMENT                    \\DONT REMOVE THIS COMMENT 

    return (
        <div>
            <div className="main">

                <div className="header-top">
                    <button onClick={()=>{setActive("open")}} className="button-nav"><img style={{width:"75%"}} src={Barside} className="Barside" alt="" />
                    </button>
                </div>

                {/* <div className="navigation" id="navigation-demo" style={{width:"83%"}}> */}
                <div className={`navigation ${active}`} id="navigation-demo" style={{width:"83%",height:"auto"}}>
                    <nav>
                        <div className="bg-light pb-2">
                            <div className="navigation-button mr-4" style={{height:"60px"}}>
                                <button onClick={()=>{setActive("")}} className="button-nav border-white"><i className="fa fa-times" style={{ fontSize: "24px" }} aria-hidden="true"></i></button>
                            </div>
                            <div className="text-center">
                                {
                                    localStorage.getItem("Shopping_token")? <img src={localStorage.getItem("photo") ? MainImgSmall : Dummy} className="sidepanel-sidebar " alt="" style={{width:"auto",height:"70px",borderRadius:"0px"}}/>
                                                                          : <img src={Sideprofile} className="sidepanel-sidebar " alt="" />
                                }
                                {/* <p className="font-weight-bold mt-2">User Name</p> */}
                                <p style={{margin:"5%"}}></p>
                            </div>
                        </div>
                      <div style={{backgroundColor:"white"}}>
                      <ul className="list-style-none pl-2" style={{display:"grid",textAlign:"initial"}}>
                            <li><Link onClick={()=>{setActive("");history.push("/Coupens")}}><img style={{width:"34px",height:"34px"}} src={Icon1} alt="" className="sidepanel-texts-img" />Coupons</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/WeeklyAd")}}><img style={{width:"34px",height:"34px"}} src={Icon2} alt="" className="sidepanel-texts-img" />Weekly Ad</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/Reaward")}}><img style={{width:"34px",height:"34px"}} src={Icon5} alt="" className="sidepanel-texts-img" />Rewards</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/cardinfo")}}><img style={{width:"34px",height:"34px"}} src={Icon9} alt="" className="sidepanel-texts-img" />Card</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/List")}}><img style={{width:"34px",height:"34px"}} src={Icon8} alt="" className="sidepanel-texts-img" />Shopping List</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/Messages")}}><img style={{width:"34px",height:"34px"}} src={Icon7} alt="" className="sidepanel-texts-img" />Messages</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/Location")}}><img style={{width:"34px",height:"34px"}} src={Icon3} alt="" className="sidepanel-texts-img" />Locations</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/Profile")}}><img style={{width:"34px",height:"34px"}} src={Icon4} alt="" className="sidepanel-texts-img" />Profile</Link></li>
                            <li><Link onClick={()=>{setActive("");history.push("/ContactUs")}}><img style={{width:"34px",height:"34px"}} src={Icon6} alt="" className="sidepanel-texts-img" />Contact Us & Legal</Link></li>
                            {
                                !localStorage.getItem("Shopping_token")?<p style={{display:"none"}}>logout</p>
                                                                       :<li><Link onClick={()=>{
                                                                        localStorage.removeItem("data");
                                                                        localStorage.removeItem("Shopping_token");
                                                                        localStorage.removeItem("menberNumber");
                                                                        localStorage.removeItem("barcode");
                                                                        localStorage.removeItem("photo");
                                                                        localStorage.removeItem("customerid");
                                                                        localStorage.removeItem("add");
                                                                        history.push("/")
                                                                    }}><img style={{opacity:"0.68",width:"30px",height:"26px",marginLeft:"3%"}} src={Icon10} alt="" className="sidepanel-texts-img" />Logout</Link></li>
                            }
                            

                        </ul>
                      </div>
                    </nav>
                </div>


            </div>
        </div>
    )
}

export default Sidebar;

