import React, { useEffect } from "react";
import { Link,useHistory } from "react-router-dom";
import prev from '../assets/img/Icons/Icons/prev.png';
import M from 'materialize-css';
import locationicon from '../assets/img/locationpin.png';
import {Url} from './url';

const Locationdetail = ()=>{

    const history = useHistory();
    const short_link = Url();
    console.log(history);

    useEffect(()=>{
        if(!history.location.state)
            history.push('/location');
    },[])

    useEffect(()=>{
        if(!localStorage.getItem("Shopping_token"))
            history.push('/Signin2');
    },[])

    function changestore()
    {
        console.log("hello world");

        const firstname = JSON.parse(localStorage.getItem("data")).firstname;
        const lastname = JSON.parse(localStorage.getItem("data")).lastname;
        const id = history.location.state.ClientStoreId;

        fetch(`${short_link}/changedprefferedstore`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                "ClientStoreId":id,
                "FirstName":firstname,
                "LastName":lastname,
                "UserToken":localStorage.getItem("Shopping_token"),
                "subdomain":localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(result=>{
            console.log("result is ",result);
            if(result.message.ErrorMessage.ErrorCode === 1)
            {
                console.log("executed")
                const ans = JSON.parse(localStorage.getItem("data"));
                console.log("ans is ",ans)
                // setStorename2(data.message[0].ClientStoreName)
                localStorage.setItem("data",JSON.stringify({
                    firstname:ans.firstname,
                    lastname:ans.lastname,
                    zipcode:ans.zipcode,
                    mobilenumber:ans.phone,
                    store:history.location.state.ClientStoreName,
                    storeid:id,
                    username:ans.username
                }))
                M.toast({html: "Your preffered store is changed successfully",classes:"#43a047 green darken-1"});
            }
        })
        .catch(err=>{
            M.toast({html: 'Something Went Wrong',classes:"#c62828 red darken-3"})
            console.log("err is arise inside the changedpreferedstore() and the err is ",err)})
    }

    function check_the_user()
    {
        if(!localStorage.getItem("Shopping_token"))
        {
            history.push('/Signin2');
        }else{}
    }


    return (
    <div>
         {check_the_user()}
        <div style={{backgroundColor:"rgb(52, 58, 64)",height:"91vh"}}>
            <div className="bg-white border shadow bg-bottom-round" style={{overflow:"auto",borderRadius:"0px",height:"100vh"}} >
        
                <div className="container page17 text-center">

                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/location">
                            <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">{history.location.state?history.location.state.City:""}</h5>
                        </div>
                    </div>
                    <br/>
                    
                    <h5 style={{textAlign:"center",margin:"2rem"}}>{history.location.state?history.location.state.AddressLine1:""}</h5>
                    <h5 style={{textAlign:"center",margin:"3%"}}>Phone :{history.location.state?<h5 style={{display:"inline-block",marginLeft:"2%"}}>({history.location.state.StorePhoneNumber.substr(0,3)}) {history.location.state.StorePhoneNumber.substr(3,3)}-{history.location.state.StorePhoneNumber.substr(6,4)}</h5>:""}</h5>
                    <img src={locationicon} style={{height:"400px"}}/>
                    <h5 style={{textAlign:"left",margin:"3%",marginLeft:"6%"}}>Hours :</h5>
                    <h5 style={{textAlign:"center",margin:"3%"}}>{history.location.state?history.location.state.StoreTimings:""}</h5>
                    
                </div>

            </div>
        </div>
        <h3 onClick = {()=>{changestore()}} className="mt-4" style={{textAlign:"center",color:"#9e3a3ad6"}}>MAKE MY STORE</h3>
    
    
    </div>
    )
}

export default Locationdetail;

{/* ------------------------------------------------------------------------*/}
