import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import Sidebar from './Sidebar'//import $ from "jquery";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MainImg from '../assets/img/reset15.png'
import prev from '../assets/img/Icons/Icons/prev.png';
import {Url} from './url'

const EmailPin = () => {
    const short_link = Url();
    const history = useHistory();
    const [state,setState] = useState(localStorage.getItem("Shopping_token")?JSON.parse(localStorage.getItem("data")).username:"")
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])

    function sendpin(){
        if(!state)
        {
            var a = document.getElementById("exampleInputEmail1");
            a.style.backgroundColor="#ead2d2";
            a.style.transition="all 1s"
            setTimeout(()=>{
                a.style.backgroundColor="white";           
            },1500)
            return;
        }
        fetch(`${short_link}/forgetpassword`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                username:state,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            if(data.message.ErrorMessage.ErrorCode === 1)
            {
                M.toast({html: "Check your E-mail",classes:"#43a047 green darken-1"});
                history.push('/Signin2')
            }
            if(data.message.ErrorMessage.ErrorCode === -1)
            {
                M.toast({html: 'This Email ID is not registered',classes:"#c62828 red darken-3"})
                history.push('/Signin2')
            }
        }).catch(err=>{
            console.log("err is arise in sendpin()")
        })        
    }

    return (
        <div>
            <div className="bg-white border shadow  bg-bottom-round" style={{overflow:"auto",height:"100vh",borderRadius:"0px"}}>
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/Setnewpin">
                            <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Set New PIN</h5>
                        </div>
                    </div>

                    <div style={{margin:"10%"}}>
                        <center><img src={MainImg} style={{height:"29vh"}} /></center>
                    </div>
                </div>
            </div>

            <div className="  p-4 mt-4" style={{ position:"absolute",bottom:"10px",borderRadius: "25px 25px 0 0", boxShadow: "0 -0.3rem 1rem rgba(0,0,0,.15)" }}>
                <div className="row ">
                    <div className="col-sm-12">
                        <p className="text-center bg-light-st pin2-sm">Your PIN will be sent to your email address associated with your account. Kindly enter the email address and click on Send PIN.</p>
                        <div className="">
                            <form className="reset-form mt-5 ">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Enter Email Address </label>
                                    <input type="email" value={state} onChange={(e)=>{setState(e.target.value)}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" required/>
                                </div>
                                <button onClick={sendpin}  type="button" className="btn btn-success w-100 fw100 mt-4 py-2">Send PIN</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailPin;