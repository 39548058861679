import React, { useEffect } from 'react';
import Footer from '../pages/footer'
import {Link,useHistory} from 'react-router-dom'
import Sidebar from './Sidebar';
import prev from '../assets/img/Icons/Icons/prev.png';

const Privacypolicy = ()=>{
    const history = useHistory();
    return(
       
        <div style={{backgroundColor:"rgb(52, 58, 64)"}}>
            <div className="bg-white border shadow  bg-bottom-round">
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Link to="/Signin2">
                            <img src={prev} width="44%"/>
                            </Link>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Set New PIN</h5>
                        </div>
                    </div>

                    <div style={{marginTop:"15%"}}>
                        <div>
                        <button type="button" onClick={()=>{history.push('/Emailpin')}} className="btn btn-danger danger_btn">Send Me Email</button>
                        </div>
                        <div>
                        <button type="button" onClick={()=>{history.push('/Smspin')}} className="btn btn-danger danger_btn">Send Me Text</button>
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer />            
        </div>
    )
}

export default Privacypolicy;