import React, { useEffect,useState } from 'react';
//import $ from "jquery";
import M from 'materialize-css';
import { Link,useHistory } from 'react-router-dom';
import ImageR from '../assets/img/Icons/Icons/image.png'

import ImageQ from '../assets/img/Icons/Icons/imageQ.png'
import ImageC from '../assets/img/Icons/Icons/imagechat.png'

import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import Barcodes from '../assets/img/Icons/Icons/checkoutbarcode.png'
import Footer from '../pages/footer'
import Sidebar from './Sidebar';
import {Url} from './url';

const Checkout = () => {
    const history = useHistory();
    const short_link = Url();
    //const data = JSON.parse(localStorage.getItem("Shopping_token")===null?"":JSON.parse(localStorage.getItem("data")));
    const [firstname,setFirstName] = useState("");
    const [lastname,setLastName] = useState("");
    const [phone,setPhoneNumber] = useState("");
    const [zipcode,setZipCode] = useState("");
    const [username,setusername] = useState("");
    const [store,setStore] = useState("");

    const [storename,setStoreName] = useState("");
    const [changethestore,setchangethestore] = useState("Select Store");
    const [membernumber,setmembernumber] = useState("");

    useEffect(() => {
        if(localStorage.getItem("Shopping_token")){
           const data = JSON.parse(localStorage.getItem("data"));
           setFirstName(data.firstname);
           setLastName(data.lastname);
           setPhoneNumber(data.mobilenumber);
           setZipCode(data.zipcode);
           setusername(data.username);
           setStore(data.store);
        }
    }, [])

    useEffect(()=>{
        fetch(`${short_link}/getclientstore`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                subdomain:localStorage.getItem("subdomain")
                })
        }).then(res=>res.json())
        .then(data=>{
             setStoreName(data);
        });
    },[])

    useEffect(()=>{
        var token = localStorage.getItem("Shopping_token")
        fetch(`${short_link}/card`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log("barcode is ",data.message.BarCodeUrl);
            localStorage.setItem("menberNumber",JSON.stringify(data.message.MemberNumber));
            localStorage.setItem("barcode",data.message.BarCodeUrl);
            setmembernumber(data.message.MemberNumber)
        })
        .catch(err=>console.log("err is arise in card"))
    },[])

    function check_the_user(){
        if(!localStorage.getItem("Shopping_token"))
        {
            history.push('/Signin2');
        }else{ 
        
        }
    }

    function changepreferedstore()
    {
        fetch(`${short_link}/storeid`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                name:store,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("data is ",data);
            console.log("id is ",data.message[0].ClientStoreId)


            // const firstname = JSON.parse(localStorage.getItem("data")).firstname;
            // const lastname = JSON.parse(localStorage.getItem("data")).lastname;

            fetch(`${short_link}/changedprefferedstore`,{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    "ClientStoreId":data.message[0].ClientStoreId,
                    "FirstName":firstname,
                    "LastName":lastname,
                    "UserToken":localStorage.getItem("Shopping_token"),
                    "MobileNumber":phone,
                    subdomain:localStorage.getItem("subdomain")
                })
            }).then(res=>res.json())
            .then(result=>{
                console.log("result after saving is ",result);
                if(result.message.ErrorMessage.ErrorCode === 1)
                {
                    M.toast({html: "successfully saved",classes:"#43a047 green darken-1"});
                    setStore(data.message[0].ClientStoreName);
                    localStorage.setItem("data",JSON.stringify({
                        firstname:firstname,
                        lastname:lastname,
                        zipcode:zipcode,
                        mobilenumber:phone,
                        store:data.message[0].ClientStoreName,
                        storeid:data.message[0].ClientStoreId,
                        username:username
                    }));
                }
            })
            .catch(err=>{console.log("err is arise inside the changedpreferedstore() and the err is ",err)})




        }).catch(err=>{
            console.log("err is arise in changepreferredstore() in /storeid and the error is ",err);
        })
    }

    function changethebackground(e)
    {
        var a = document.getElementsByClassName("parent")
        for(let i=0;i<a.length;i++){
            a[i].style.backgroundColor = "white";
        }
        e.target.style.backgroundColor = "#ece6e6";
    }

    return (
        <div style={{background:"#343a40"}}>
            {check_the_user()}
            <div className="bg-white border shadow bg-bottom-round" style={{overflowY:"scroll"}}>
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                        <Sidebar />
                            {/* <p className="mb-0"><Link to="/" className="text-decoration-none"><img src={Barside} className="Barside" alt="" /></Link></p> */}
                        </div>
                        <div className="col-sm-11-my17 text-center ">

                            <h5 className=" signin1-h1-top  mb-0">Profile</h5>
                        </div>

                    </div>


                    <div className="row page17-p">
                        <div className="col-sm-12 py-2 ">
                            {/* <hr className="mt-2" /> */}

                            <div className="row  scroll-inner">
                                <div className="col-sm-12">
                                     <p className="text-red text-center">MEMBER #: {localStorage.getItem("menberNumber")?JSON.parse(localStorage.getItem("menberNumber")):"-----"}</p> 
                                    {/*<p className="text-red text-center">MEMBER #: {membernumber?<p style={{display:"inline-block"}}>{membernumber}</p>:"-----"}</p>*/}
                                    <img src={localStorage.getItem("barcode")?localStorage.getItem("barcode"):"----"} style={{position:"relative",left:"2%",width:"auto",height:"auto"}} className="List-checkout-barcode" alt="" />

                                    <div style={{margin:"0px 2%"}}>
                                        <form style={{marginTop:"15px"}}>
                                            <div style={{display:"flex",marginTop:"22px",marginLeft:"15px"}}>
                                                <div>
                                                    <label style={{fontStyle:"normal"}}>FirstName</label>
                                                    <input style={{width:"70%"}} type="email" className="form-control" onChange={(e)=>{setFirstName(e.target.value)}} value={firstname} id="inputEmail4" placeholder="FirstName" />
                                                </div>
                                                <div>
                                                    <label style={{fontStyle:"normal"}}>LastName</label>
                                                    <input style={{width:"70%"}} type="email" className="form-control" onChange={(e)=>{setLastName(e.target.value)}} value={lastname} id="inputEmail4" placeholder="lastName" />
                                                </div>
                                            </div>

                                            <div style={{display:"flex",marginTop:"22px",marginLeft:"15px"}}>
                                                <div>
                                                    <label style={{fontStyle:"normal"}}>Phone Number</label>
                                                    <input style={{width:"70%"}} type="email" className="form-control" onChange={(e)=>{setPhoneNumber(e.target.value)}} value={phone} id="inputEmail4" placeholder="" />
                                                </div>
                                                <div>
                                                    <label style={{fontStyle:"normal"}}>Zip Code</label>
                                                    <input style={{width:"70%"}} type="email" className="form-control" onChange={(e)=>{setZipCode(e.target.value)}} value={zipcode} id="inputEmail4" placeholder="Zip code" />
                                                </div>
                                            </div>

                                            <div style={{display:"flex",marginTop:"10px",marginLeft:"12px"}}>
                                                <div>
                                                    <label style={{fontStyle:"normal"}}>UserName</label>
                                                    <input style={{width:"100%"}} value={username} onChange={(e)=>{}} type="email" className="form-control" id="inputEmail4" placeholder="Email" />
                                                </div>
                                                
                                                <a href="#" style={{margin:"auto",color:"red",display:"none"}}>Change</a>
                                            </div>
                                            <div style={{display:"flex",marginTop:"6px",marginLeft:"12px"}}>
                                                <div>
                                                    <label style={{fontStyle:"normal"}}>Store</label>
                                                    <input style={{width:"100%"}} value={store} type="email" className="form-control" id="inputEmail4" placeholder="Email" disabled/>
                                                </div>
                                                
                                                {/* <Link onClick={()=>{console.log("you pressed me")}} href="#" style={{margin:"auto",color:"red"}}>Change</Link> */}
                                                <div className="col-sm-3-my17-C text-center " style={{margin:"auto"}}>
                                                <a className="text-dark fw500" data-toggle="modal" data-target="#exampleModal1" ><span style={{fontSize: "14px",color:"red"}}>Change</span></a>
                                                </div>
                                            </div>

                                            {/* -------------------Modal Box------------------------------ */}




                                            {/* <div style={{ maxHeight:"100%", background: "rgba(255, 255, 255, 0)"}} className="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
                                            <div style={{ maxHeight:"100%", background: "rgba(255, 255, 255, 0)"}} className="modal fade" id="exampleModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-message rounded-lg-15" style={{marginTop:"50%",padding:"0px"}}>
                                <div className="modal-body p-0 ">
                                    <p className="bg-danger text-white text-center py-3 rounded-lg-15-lrt">{changethestore}</p>
                                    <ul className="list-group list-group-flush px-3 text-center">
                                        {storename ?    <>
                                                        {
                                                            storename.message.map((item,i)=>{
                                                                //console.log("item hai bhai ",item);
                                                                return (<li key={i} id={i} onClick={(e)=>{
                                                                    setchangethestore(item);
                                                                    changethebackground(e);
                                                                }} className="list-group-item border-top-0 parent">{item}</li>)
                                                            })
                                                        }      
                                                        </>
                                                        :
                                                        <>
                                                        <li className="list-group-item border-top-0"><a href="" className="text-decoration-none text-dark">Big Bazaar Store</a></li>
                                                        </>
                                        }  
                                    </ul>
                                    <div className="text-center px-3 mb-3 mt-1">
                                        <button onClick={()=>{setStore(changethestore)}} type="button" className="btn btn-success py-1 w-100" data-dismiss="modal">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>















                                            {/* -------------------End of Modal Box------------------------------ */}
                                <div className="col-sm-12 bottom-btm-font mt-2 text-center">
                                    <button onClick={changepreferedstore} type="button" className="btn btn-success w-100 mt-3 pt-1 pb-1 "><small>Save</small></button>
                                    <div className="row mx-auto mb-2">
                                        <div className="col  pl-0 pr-1">
                                            <button onClick={()=>{history.push('/Reset15')}} type="button" className="btn btn-outline-danger w-100 px-0 mt-2 py-0 text-center" ><small className="fw600">Change PIN</small></button>
                                        </div>
                                        <div className="col pr-0 pl-1">
                                            <button type="button" className="btn btn-outline-danger w-100 mt-2 py-0 text-center" ><small className="fw600" onClick={()=>{
                                                localStorage.removeItem("data");
                                                localStorage.removeItem("Shopping_token");
                                                localStorage.removeItem("menberNumber");
                                                localStorage.removeItem("barcode");
                                                localStorage.removeItem("photo");
                                                localStorage.removeItem("customerid");
                                                localStorage.removeItem("add");
                                                history.push("/")
                                            }}>Logout</small></button>
                                        </div>
                                    </div>
                                    <Link to="Messages" className=" text-center text-decoration-none text-danger  profile-msg-link"><i className="fa fa-envelope-o text-danger pr-2" aria-hidden="true"></i>Messages</Link>
                                </div>
                                            <br/>
                                            <h6 style={{float:"left",display:"none"}}>Support</h6>
                                            <h6 style={{float:"right",display:"none"}}>Legal And Privacy</h6>
                                        </form>
                                    </div>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>

                </div>

            </div>
            <Footer name="profile"/>
        </div>
        
    )
}

export default Checkout;

