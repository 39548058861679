import React, { useEffect,useState } from "react";
import {useParams} from 'react-router-dom';
import { Link,useHistory } from 'react-router-dom';
import prev from '../assets/img/Icons/Icons/prev.png';
import Cardskeleton2 from '../pages/Skeleton2';
import Sidebar from './Sidebar';
import Barside from '../assets/img/Icons/Icons/Icon-17.png'

import RedL from '../assets/img/Icons/Icons/sm-barcode.png'


import MainImgSmall from '../assets/img/Icons/Icons/smallimgicon.png'

import LPin from '../assets/img/Icons/Icons/Lpin.png'
import Footer from '../pages/footer'
import {Url} from './url'

const Coupons = (props)=>{
    const history = useHistory();
    const short_link = Url();

    console.log("hello the props is ",props.location.value);
    const {id} = useParams();
    console.log("my id is ",id);

    const [state,setState] = useState();
    const [timeleft,setTimeLeft] = useState();

    useEffect(()=>{

        var token = localStorage.getItem("Shopping_token");

        fetch(`${short_link}/coupons/${id}`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log(data.message[0].ImagePath)
            console.log(data.message[0].CustomerName)
            console.log("time left is ",data.message[0].ExpiresOn)
            console.log("the data of the coupons is ",data);
            setState(data);
            var a = data.message[0].ExpiresOn;
            var b = a.substr(6,13);
            var c = parseInt(b);
            var d = c - Date.now();
            var minsday = 1000*3600*24;
            var expirydate = (d/minsday);
            var e = parseInt(expirydate);
            console.log(e)
            setTimeLeft(e);

        }).catch(err=>{
            console.log("error is arise in useEffect in coupons section and the err is ",err);
        })
    },[])

    async function add_to_list(item)
    {
        return new Promise((resolve,reject)=>{
                fetch(`${short_link}/addtolist`,{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    data:item,
                    token:localStorage.getItem("Shopping_token"),
                    subdomain:localStorage.getItem("subdomain")
                })
                }).then(res=>res.json())
                .then(data=>{
                    console.log(data)
                    resolve(data)
                })
                .catch(err=>{
                    console.log(err);
                    reject(err);
                })
        })
    }

    function remove_to_list(item)
    {
        return new Promise((resolve,reject)=>{
            fetch(`${short_link}/removetolist`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                data:item.SSNewsId,
                token:localStorage.getItem("Shopping_token"),
                subdomain:localStorage.getItem("subdomain")
            })
            }).then(res=>res.json())
            .then(data=>{
                console.log(data)
                resolve(data)
            })
            .catch(err=>{
                console.log(err);
                reject(err);
            })
        })
    }

    function republished()
    {
        var token = localStorage.getItem("Shopping_token");
        fetch(`${short_link}/coupons/${id}`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log(data.message[0].ImagePath)
            console.log(data.message[0].CustomerName)
            console.log("time left is ",data.message[0].ExpiresOn)
            setState(data);
        }).catch(err=>{
            console.log("error os coming");
        })
    }

    function agent(item)
    {
        var a = item.ExpiresOn;
        var b = a.substr(6,13);
        var c = parseInt(b);
        var d = c - Date.now();
        var minsday = 1000*3600*24;
        var expirydate = (d/minsday);
        var e = parseInt(expirydate);
        console.log("the time is ",e);
        return e;
    }

    return (
        <div style={{background:"#343a40"}}>
            <div className="bg-white border shadow bg-bottom-round" style={{overflowY:"scroll"}}>
                <div className="container page17">
                        
                        <div className="row mt-3">

                            <div className="col-sm-1-my text-center" style={{width:"11%"}}>
                                <Link to={{pathname:"/coupens",value:"department"}} className="text-decoration-none">
                                    <img src={prev} width="44%"/>
                                </Link>
                            </div>

                            
                                <div className="col-sm-11-my17 text-center " style={{width:"86%"}}><h5 className=" signin1-h1-top  mb-0">{
                                        !state ? <h3>...</h3>:
                                        <h3>{props.location.state}</h3>
                                    }</h5></div>
                            

                            
                        </div>

                        <div className="row page17-p">
                            <div className="col-sm-12">
                                <hr className="mt-2" />

                                <div className=" row " style={{display:"none"}}>
                                    <div className="col-sm-12">
                                        <div className="input-group mb-3 shadow">
                                            <input type="text" className="form-control border-right-0" placeholder="Enter keyword  to search" style={{ height: "47px",width:"70%"}} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <div className=" input-group-append border-left-0">
                                                <span className="input-group-text bg-white p-1 border-left-0 " id="basic-addon2">
                                                    <img src={RedL} alt="" style={{objectFit:"cover"}} className=" mr-2" style={{ height: "80%", width: "30px" }} /><i className="fa fa-search pr-1" style={{ fontSize: "18px" }} aria-hidden="true"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                

                                <div className="tab-pane" id="tabs-2" role="tabpanel">
                                    <div className="row px-3 scroll-inner4" style={{height:"78vh"}}>
                                        
                                        {
                                            !state ? <> <Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 /> </>
                                                   :
                                                <>
                                                {
                                                state.message.map((item,i)=>{
                                                
                                                return (<div className="col-sm-12 mt-2 mb-1" key={i}>
                                                <div className=" card-hori-t">
                                                    <div className="row ">       
                                                        <div onClick={()=>{history.push({pathname:"/CoupensDetail",state:item,value:"departmentdata",id:id,name:props.location.state})}} className="col-sm-5-my-C px-0">
                                                            <div className="reward-card-img-outer-left-C reward-card-img-outer-left">
                                                                <img src={item.ImagePath} alt="" className="reward-card-img-left" />
                                                                <div>
                                                                    <p className="bg-danger coupen-middle-card-bagde"><small className="text-white pl-2">{item.PLUCode === "100%" ? <>Free</> :<>Save {item.PLUCode}</>}</small></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                        <div className="col-sm-7-my-C bg-light card-img-right ">
                                                            <div className="p-3">
                                                                
                                                                <div className="mb-3" onClick={()=>{history.push({pathname:"/CoupensDetail",state:item,value:"departmentdata",id:id,name:props.location.state})}}>
                                                                    <p className="float-right mb-0"><small className="text-danger">{agent(item)} days left</small></p>
                                                                    <br />
                                                                    <p className=" List-card-img-right-p1 mt-1 mb-1">{item.Title.substr(0,30)}</p>
                                                                    <p className="mb-0 List-card-img-right-p2 text-secondary-light-gray">{item.Details.substr(0,20)}...</p>
                                                                </div>

                                                                {
                                                                    !item.IsInCart?<button onClick={(e)=>{
                                                                        e.preventDefault();
                                                                        add_to_list(item).then(data=>{
                                                                            console.log(typeof(data.message.ErrorMessage.ErrorCode));
                                                                            if(data.message.ErrorMessage.ErrorCode === 1)
                                                                            {
                                                                                console.log("now republished is going to execute");
                                                                                republished();
                                                                            }
                                                                        })
                                                                        .catch(err=>console.log(err))
                                                                    }}type="button" className="btn btn-success">Add to list</button>
                                                                                  :<button onClick={(e)=>{
                                                                                      e.preventDefault();
                                                                                    remove_to_list(item).then(data=>{
                                                                                        console.log("data i received is ",data.message);
                                                                                        console.log("typeof ",typeof(data.message))
                                                                                        if(data.message === 1)
                                                                                        {
                                                                                            console.log("now republished is going to execute");
                                                                                            republished();
                                                                                        }
                                                                                    });
                                                                                  }} type="button" className="btn btn-danger">Remove from list</button>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                                })
                                                }
                                                </>                           
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Coupons;