import React, { useEffect } from 'react';
//import $ from "jquery";

import { Link,useHistory } from 'react-router-dom';
import ImageR from '../assets/img/Icons/Icons/image.png'
import Cardskeleton2 from '../pages/Skeleton2';
import ImageQ from '../assets/img/Icons/Icons/imageQ.png'
import ImageC from '../assets/img/Icons/Icons/imagechat.png'
import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import M from 'materialize-css';
import Imgcard1 from '../assets/img/Icons/Icons/cameraI.jpg'
import Sidebar from './Sidebar';
import Footer from './footer'
import {Url} from './url'

const Reaward = () => {


    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])
    const [state,setState] = React.useState();
    const [membernumber,setmembernumber] = React.useState("------");

    const history = useHistory();
    const short_link = Url();

    useEffect(()=>{
            var token = localStorage.getItem("Shopping_token")
         fetch(`${short_link}/myRewards`,{
         //  fetch('/myRewards',{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("data of my reward is ",data);
            if(data.message.ErrorMessage.ErrorCode === -1)
            {
                M.toast({html: "Currently there is no reward",classes:"#c62828 red darken-3"});
                console.log(data.message.LMRewards)
                setState(data);
            }
            else
            {

                setState(data);
            }
            
        }).catch(err=>{
            console.log("err is come in rewards section the error is ",err);
        })
    },[])

    useEffect(()=>{
        var token = localStorage.getItem("Shopping_token")
        fetch(`${short_link}/card`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log("barcode is ",data.message.BarCodeUrl);
            localStorage.setItem("menberNumber",JSON.stringify(data.message.MemberNumber));
            localStorage.setItem("barcode",data.message.BarCodeUrl);
            setmembernumber(data.message.MemberNumber);
        })
        .catch(err=>console.log("err is arise in card"))
    },[])

    function check_the_user(){
        if(!localStorage.getItem("Shopping_token"))
        {
            history.push('/Signin2');
        }else{ 
        
        }
    }

    

    return (
        <div style={{background:"#343a40"}}>
            {check_the_user()}
            <div className="bg-white border shadow  bg-bottom-round">
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                        <Sidebar />
                            {/* <p className="mb-0"><Link to="/" className="text-decoration-none"><img src={Barside} className="Barside" alt="" /></Link></p> */}
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">My Rewards</h5>
                        </div>

                    </div>

                    <div className="row page17-p">
                        <div className="col-sm-12 py-2 ">
                            <hr className="mt-2" />

                            <div className="row  scroll-inner">
                                <div className="col-sm-12" style={{height:"5%"}}>
                                    <p className="text-red text-center">MEMBER #: {membernumber}</p>
                                </div>
                                
                                {
                                    
                                    
                                        
                                    !state ? <>
                                                <Cardskeleton2 />
                                            </>
                                           :    <div style={{position:"absolute",top:"11%",overflow:"scroll",height:"64vh"}}>
                                               {
                                                state.message.LMRewards === null ? <div style={{display:"none"}}>hello</div>
                                                                                 :

                                                    state.message.LMRewards.map((item,i)=>{
                                                        var a = parseInt(item.PurchasedQty);
                                                        var b = parseInt(item.BuyQtyAmount);
                                                        var percent = a/b*100;
                                                        var quant = parseInt((a/b)*10);
                                                        
                                                        console.log("value of a is ",a);
                                                        console.log("value of b is ",b);
                                                        console.log("quant is ",quant);
                                                        console.log("the % is ",percent);
                                                        
                                                        setTimeout(()=>{
                                                            var a = document.getElementsByClassName("parent1");
                                                            if(quant<10){
                                                            for(let i=0;i<quant;i++)
                                                            {
                                                                a[i].checked = true
                                                            }
                                                        }
                                                        },2000)
                                                        
                                                        
                                                        return (<div className="col-sm-12 mt-2 mb-1" key={item.RewardTypeId}>
                                                            <div className="px-3 card-hori-t">
                                                                <div className="row ">
                                                                    <div className="col-sm-4-my px-0">
                                                                        <div className="reward-card-img-outer-left">
                                                                            <img src={item.ImageUrl} alt="" className="reward-card-img-left" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-8-my bg-light card-img-right">
                                                                        <div className="p-3">
                                                                            <p className="mb-0 card-img-right-p1-R">{item.Title}</p>
                                                                            <div className="row">
                                                                                <div className="col-12">  
                                                                                {
                                                                                        item.RewardTypeId === 2 ?   <div className="progress">        
                                                                                                                        <div className="progress-bar bg-success" role="progressbar" style={{width: `${percent}%` }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">{item.BuyQtyAmount}</div>
                                                                                                                    </div>
                                                                                                                :   <div id="parent" style={{display:"flex",marginTop:"20px",marginBottom:"20px",textAlign:"center"}}>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        <input className="parent1" type="radio"  style={{position:"relative",opacity:"1",marginRight:"5px"}}/>
                                                                                                                        
                                                                                                                    </div>
                                                                                }

                                                                                
                                                                                
                                                                                


                                                                                </div>

                                                                                <div className="col-sm-4 col" style={{display:"none"}}>
                                                                                    <p className="mb-0 text-left"><small className="font-weight-bold text-secondary-light-gray ml-2" style={{color: "black",fontStyle: "italic"}}>{item.BuyQtyAmount}</small></p>
                                                                                </div>
                                                                                <div className="col-sm-8 col">
                                                                                    <Link to={{pathname:"/RewardDetails",state:item}} className="text-decoration-none fw600 card-img-right-p2-R text-red float-right">View Details</Link>
                                                                                </div>

                                                                                {/* code of a progress bar */}
                                                                                {/* <div class="progress">        
                                                                                    <div class="progress-bar" role="progressbar" style={{width: "80%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">500000</div>
                                                                                </div> */}
                                                                                
                                                                                {/* code of the  */}
                                                                                {/* <div style={{display:"flex",marginTop:"4%",marginLeft:"9%"}}>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                    <input type="radio" id="css" name="fav_language" value="CSS" style={{position:"relative",opacity:"1",marginRight:"5%"}}/>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)       //end of return 
                                                    })                //end of map 
                                                }           {/* end of the echmascript tag */}
                                            </div>
                                }
                                
                                
                                
                                
                                
                                
                                
                                
           


                            </div>


                        </div>
                    </div>
                </div>

            </div>
            <Footer name="reaward"/>
        </div>
    )
}

export default Reaward;

