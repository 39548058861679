//there is a file card_info.jsx which is similar to the list.jsx


import React, { useEffect,useState } from 'react';
import Sidebar from './Sidebar';
import M from 'materialize-css';
import { Link,useHistory } from 'react-router-dom';
import ImageR from '../assets/img/Icons/Icons/image.png'
import Cardskeleton2 from '../pages/Skeleton2';
import ImageQ from '../assets/img/Icons/Icons/imageQ.png'
import ImageC from '../assets/img/Icons/Icons/imagechat.png'
import ImageCamera from '../assets/img/Icons/Icons/cameraI.jpg'
import dummy from '../assets/img/dummyproduct.png';
import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import MainImgSmall from '../assets/img/Icons/Icons/smallimgicon.png'
import Imgcard1 from '../assets/img/Icons/Icons/cameraI.jpg'
import RedL from '../assets/img/Icons/Icons/sm-barcode.png'

import Barcodes from '../assets/img/Icons/Icons/checkoutbarcode.png'
import Footer from '../pages/footer'

import {Url} from './url';


const List = (props) => {

    const [state,setState] = useState();
    const [state2,setState2] = useState();
    const [name,setName] = useState();
    
    const history = useHistory();
    const short_link = Url();

    console.log("history is",history)

    const [active,setActive] = useState("active");
    const [active2,setActive2] = useState("active");

    const [searchdata,setsearchdata] = useState("");
    const [savesearchdata,setsavesearchdata] = useState([]);

    useEffect(()=>{
        if(localStorage.getItem("add"))
            setsavesearchdata(localStorage.getItem("add").split(","))
        
    },[])

    useEffect(()=>{
        var token = localStorage.getItem("Shopping_token")
        fetch(`${short_link}/cart`,{
            // fetch('/cart',{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("the data of cart is ",data)
            //console.log(data.message[0].ImagePath)
            setState(data);
        })
        .catch(err=>{
            console.log("err is arise in cart and the error is",err);
        })

        fetch(`${short_link}/card`,{
            // fetch('/card',{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log(data);
            console.log("barcode is ",data.message.BarCodeUrl);
            localStorage.setItem("menberNumber",JSON.stringify(data.message.MemberNumber));
            localStorage.setItem("barcode",data.message.BarCodeUrl);
            setState2(data);
            setName(JSON.parse(localStorage.getItem("data")).firstname);
        })
        .catch(err=>console.log("err is arise in card"))

    },[])


    function check_the_user(){
        if(!localStorage.getItem("Shopping_token"))
        {
            history.push('/Signin2');
        }else{ 
        
        }
    }

    function fun()
    {
        var a = document.getElementById("search");
        a.style.display = "block"
    }

    function fun2()
    {
        var a = document.getElementById("search");
        a.style.display = "none"
    }

    function addthedata()
    {
        
        if(searchdata.length === 0 || searchdata.length === 1)
        {
            window.alert("enter more than 1 character");
            return;
        }
        localStorage.setItem("add",[...savesearchdata,searchdata]);
        setsavesearchdata((olditem)=>{
            return [...olditem,searchdata];
        })
        setsearchdata("");
    }

    function clearthelist()
    {
        var token = localStorage.getItem("Shopping_token");
        console.log("the state is ",state);
        console.log("the token is ",token);
        console.log("the length os the item is ",state.message.length);
        let i;
        for(i=0;i<state.message.length;i++)
        {
            var a = state.message[i].OfferId;
            fetch(`${short_link}/clearlist`,{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    offerid:a,
                    token:token,
                    subdomain:localStorage.getItem("subdomain")
                })
            }).then(res=>res.json())
            .then(data=>{
                console.log(data);
            })
            .catch(err=>{
                console.log("the error is arise and the error is ",err);
            })
        }
        //document.getElementById("tabs-1").style.display = "none";
        //  var a = document.getElementById("tabs-1");
        //  console.log("the value inside the tabs-1 is ",a);
        
            var a = document.getElementsByClassName("colsm123");
            for(let i=0;i<a.length;i++)
                a[i].style.display = "none";
            var b = document.getElementsByClassName("parent1");
            for(let i=0;i<b.length;i++)
                b[i].style.display = "none";
            localStorage.removeItem("add");
            setsavesearchdata([]);
    }

    function remove_to_list(item)
    {
        return new Promise((resolve,reject)=>{
            fetch(`${short_link}/removetolist`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                data:item.OfferId,
                token:localStorage.getItem("Shopping_token"),
                subdomain:localStorage.getItem("subdomain")
            })
            }).then(res=>res.json())
            .then(data=>{
                console.log("data of remove to list is ",data)
                resolve(data)
            })
            .catch(err=>{
                console.log(err);
                reject(err);
            })
        })
    }

    function republished()
    {
        var token = localStorage.getItem("Shopping_token")
        fetch(`${short_link}/cart`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                token:token,
                subdomain:localStorage.getItem("subdomain")
            })
        }).then(res=>res.json())
        .then(data=>{
            console.log("the data of cart is ",data)
            setState(data);
            M.toast({html: 'Cart Remove Successfully',classes:"#c62828 red darken-3"})
        })
        .catch(err=>{
            console.log("err is arise in cart and the error is",err);
        })
    }

    function removethedata(item)
    {
        // console.log(item);
        setsavesearchdata((olditem)=>{
            return olditem.filter((i)=>{
                if(i !== item)
                    return i;
            })
        })
        var item1 = localStorage.getItem("add");
        var item2 = item1.split(",");
        var item3 = item2.filter((i)=>{
            if(i!==item)
                return i;
        })
        localStorage.setItem("add",item3);
    }

    return (
        <div style={{background:"#343a40"}}>
            {check_the_user()}
            <div className="bg-white border shadow bg-bottom-round" style={{overflowY:"scroll"}}>
                <div className="container page17">
                    <div className="row mt-3">
                        <div className="col-sm-1-my17 text-center ">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9-my17 text-center ">

                            <h5 className=" signin1-h1-top ml-5 mb-0">List</h5>
                        </div>

                        <div onClick={clearthelist} className="col-sm-3-my17 text-center ">
                            <a className="text-dark fw500 ">Clear List</a>
                        </div>

                    </div>

                    <div className="row page17-p">
                        <div className="col-sm-12 py-2">
                            <hr className="mt-2" />

                            <div id="search" className=" row px-3">
                                <div className="col-sm-11-LSB" style={{width:"100%"}}>
                                    <div className="input-group px-3 mb-1 shadow">
                                        <input id="searchvalue" value={searchdata} onChange={(e)=>{setsearchdata(e.target.value)}} type="text" className="form-control border-right-0" placeholder="Enter Item Name " style={{ width:"65%",height: "47px", fontFamily: "Arial, FontAwesome" }} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                        <div className=" input-group-append border-0">
                                            <span className="input-group-text bg-white p-1 border-0" id="basic-addon2"><button onClick={addthedata} type="button" className="btn btn-success">Add</button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div>
                                {/* {render_list()} */}
                                <ul className="nav nav-tab mt-2 mb-2" role="tablist">
                                    <li onClick={fun} className="nav-item w-50">
                                        <a className="nav-link active text-center" data-toggle="tab" href="#tabs-1" role="tab"><small>Shopping List</small></a>
                                    </li>
                                    <li onClick={fun2} className="nav-item w-50">
                                        <a className="nav-link text-center" data-toggle="tab" href="#tabs-2" role="tab"><small>Check Out</small></a>
                                    </li>
                                </ul>


                                <div className="tab-content">
                                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                        <div className="row  scroll-inner2 " id="parent">

                                            

                                                            {
                                                                !state ?   <>
                                                                            <Cardskeleton2 /><Cardskeleton2 /><Cardskeleton2 />
                                                                            </>
                                                                          :
                                                                    <>
                                                                    {
                                                                        state.message.map((item,i)=>{
                                                                            return(<div className="col-sm-12" key={i} className="colsm123" style={{width:"95%",marginLeft:"auto"}}>
                                                                            <div className="px-1">
                                                                                <div className="row m-0 mt-1">
                                                                                    <div className="col-sm-3-my px-0">
                                                                                        <div className="List-card-img-outer-left">
                                                                                            <img src={item.ImagePath} alt="" className="card-img-left" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-9-my bg-light List-card-img-right">
                                                                                        <div className="p-3">
                                                                                            <p className=" List-card-img-right-p1">{item.ProductName}</p>
                                                                                            <p className="mb-0 List-card-img-right-p2 text-secondary-light-gray">{item.Details.substr(0,30)}...</p>
                                                                                            <button onClick={(e)=>{
                                                                                                e.preventDefault();
                                                                                                remove_to_list(item).then(data=>{
                                                                                                    console.log("data.message is ",data.message);
                                                                                                    if(data.message === 1)
                                                                                                    {
                                                                                                        republished()
                                                                                                    }
                                                                                                })
                                                                                                console.log("hello");
                                                                                            }} type="button" className="btn btn-danger">Remove From List</button>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                            
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                        })
                                                                    }
                                                                    </>                           
                                                            }

                                                {/* ----------------------this will appear when user click on the add button ------------------------------------------- */}
                                                            
                                                            {
                                                                savesearchdata.map((item,i)=>{

                                                                    return(<div key={i} style={{height:"16vh"}} className="parent1">
                                                                        <div className="col-sm-12" style={{padding:"0px"}}>
                                                                            <div className="px-1">
                                                                                <div className="row m-0 mt-1">
                                                                                    <div className="col-sm-3-my px-0">
                                                                                        <div className="List-card-img-outer-left">
                                                                                            <img src={dummy} alt="" className="card-img-left" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-9-my bg-light List-card-img-right">
                                                                                        <div className="p-3">
                                                                                            <p className="List-card-img-right-p1" className="ohno">{item}</p>
                                                                                            <button onClick={()=>{removethedata(document.getElementsByClassName("ohno")[i].innerHTML)}} type="button" className="btn btn-danger">Remove From List</button>
                                                                                        </div>
                                                                                    </div>
                            
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                                })
                                                            }
                                                             

                                                {/* ----------------------------------------------------------------- */}
                                                
                                        </div>
                                    </div>



                                    {/* ------------------------------------------------------- */}
                                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                                        <div className="row px-4 " style={{ marginTop: "25%" }}>
                                            <div className="row text-center" id="tabs-2">
                                                <img style={{margin:"auto",marginBottom:"10%"}} src={MainImgSmall} alt="" height={40} width="auto" className="mt-4" />
                                                <div className="col-sm-12">
                                                    
                                                    <h5 className="fw600 mb-3">{name?name:"Username"}</h5>
                                                    <p className="mb-1" style={{marginTop:"4%"}}><small className="fw600" style={{fontSize:"17px"}}>{!state2 ? <></> : state2.message.MemberNumber}</small></p>
                                                    <p style={{marginTop:"200px"}} className="text-uppercase mb-1"><small style={{fontSize:"13px",fontWeight:"500px"}}>Scan this barcode at checkout</small></p>
                                                    <div>
                                                    {
                                                    !state2 ? <Cardskeleton2 />
                                                            : <img src={state2.message.BarCodeUrl} className="checkout-barcode-CN px-5" alt="" />
                                                    }
                                                    </div>
                                                    <h6 className="text-dark fw600 mt-5" style={{fontSize:"large" , display:"none"}}>Congrats!</h6>
                                                    <h6 className="text-dark fw600" style={{fontSize:"large" , display:"none"}}>You have saved</h6>
                                                    <div className="px-5" style={{marginTop:"-8%", display:"none"}}>
                                                        <Link to="/Register1" type="button" className="btn btn-outline-success w-100 mt-5" style={{fontSize:"17px",paddingTop:"7%",paddingBottom:"12%"}}><h5 className="fw600">$100.00</h5></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* -------------------------------------------------------------------------------- */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer name="list"/>
        </div>
    )
}

export default List;