import React, { useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import MainImg from '../assets/img/signin1.png'
import Icon1 from '../assets/img/Icons/Icons/Icon-01.png'

import Icon2 from '../assets/img/Icons/Icons/Icon-06.png'

import Icon3 from '../assets/img/Icons/Icons/Icon-02.png'
import Icon4 from '../assets/img/Icons/Icons/Icon-07.png'

import Icon5 from '../assets/img/Icons/Icons/Icon-03.png'
import Icon6 from '../assets/img/Icons/Icons/Icon-08.png'


import Icon7 from '../assets/img/Icons/Icons/Icon-04.png'
import Icon8 from '../assets/img/Icons/Icons/Icon-09.png'

import Icon9 from '../assets/img/Icons/Icons/Icon-05.png'
import Icon10 from '../assets/img/Icons/Icons/Icon-10.png'

import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import Footer from '../pages/footer'
import RedL from '../assets/img/Icons/Icons/red.png'
import prev from '../assets/img/Icons/Icons/prev.png';
import Sidebar from './Sidebar';
const RewardDetails = () => {
    
    const history = useHistory();
    console.log("the history of the demo is ",history);
    // console.log("the detail is ",typeof(history.location.state.RewardDetails));

    useEffect(()=>{
        if(!history.location.state)
            history.push('/Reaward')
    },[])

    function check_the_user(){
        if(!localStorage.getItem("Shopping_token"))
        {
            history.push('/Signin2');
        }
        if(!history.location.state)
            history.push({pathname:"/Reaward"})
        else{ 
        
        }
    }

    return (

        <div style={{background:"#343a40"}}>
            {check_the_user()}
            <div className="bg-white border shadow  bg-bottom-round" style={{overflowY:"scroll"}}>
                <div className="container page17">

                    <div className="row mt-3">
                        <div onClick={()=>{history.push({pathname:'/Reaward'})}} className="col-sm-1-my17 text-center ">
                            {/* <Sidebar /> */}<img src={prev} width="44%"/>
                        </div>
                        <div className="col-sm-11-my17 text-center ">
                            <h5 className=" signin1-h1-top  mb-0">Reward Details</h5>
                        </div>
                    </div>

                    <div className="row page17-p">
                        <div className="col-sm-12 py-2 ">
                            <hr className="mt-2 mb-0" />
                            <div className="row">
                                <div className="col-sm-12 px-0">
                                    <div>
                                        <center><img style={{width:"auto"}} src={history.location.state?history.location.state.ImageUrl:""} alt="" className="img-bagde-hero"/></center>
                                    </div>
                                </div>
                                <div style={{width:"100%",margin:"4%",marginBottom:"0px"}}>
                                    <p style={{fontSize:"large"}}>{history.location.state?history.location.state.Title:""}</p>
                                    {
                                        !history.location.state ?   <></> :
                                                                        history.location.state.RewardDetails === "" ? <></>
                                                                                    :   <>
                                                                                            <div style={{backgroundColor:"black",color:"white",boxSizing:"border-box", padding:"0.5rem"}}>
                                                                                            <h5 className="m-0">ADDITIONAL DETAILS</h5>
                                                                                            </div>
                                                                                            <p style={{fontSize:"12px",margin:"10px 0px", textAlign:"justify"}}>{history.location.state?history.location.state.RewardDetails:""}</p>
                                                                                        </>
                                    }
                                    
                                    <p style={{fontSize:"12px",margin:"10px 0px", textAlign:"justify"}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer name="reaward"/>            
        </div>
    )
}

export default RewardDetails;

