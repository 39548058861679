import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import MainImg from '../assets/img/signin1.png'
import Icon1 from '../assets/img/Icons/Icons/Icon-11.png'
import { useHistory } from 'react-router-dom';
import Icon2 from '../assets/img/Icons/Icons/Icon-12.png'
import Icon3 from '../assets/img/Icons/Icons/Icon-15.png'
import Icon4 from '../assets/img/Icons/Icons/Icon-13.png'
import Icon5 from '../assets/img/Icons/Icons/Icon-14.png'
import Icon6 from '../assets/img/Icons/Icons/Icon-16.png'

import MainImgSmall from '../assets/img/Icons/Icons/smallimgicon.png'
const Signin1 = () => {

    const history = useHistory();
function check_the_user()
{
    if(localStorage.getItem("Shopping_token"))
        history.push('/Coupens');
    else{}
}

    return (
        <div>
            {check_the_user()}
            <div className="bg-imgs1">
                <div className="overlay-white">
                    <div className="container" style={{height:"90vh"}}>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                {/* <img src={MainImgSmall} alt="" srcset="" className="MainImgSmall-s2 mt-4" /> */}
                                <img src={MainImgSmall} alt="" srcSet="" className="MainImgSmall-s2 mt-4" />

                                <h2 className="font-weight-bold signin1-h2 mt-5 pt-3" style={{ fontSize: "30px" }}>Welcome</h2>
                                <p className="text-dark fw00 mb-4 mt-2" style={{ fontSize: "15px" }}>Sign in to learn more about digital savings, coupons, rewards for loyalty members.</p>
                            </div>

                        </div>
                        <div className="row mt-4 btn-card-sm-s1 px-2">
                            <div className="  col-sm-4 col px-2 text-center">
                                <Link to="/coupens">
                                    <div className="b0x6font border rounded-lg text-secondary-light-gray bg-light btn-ligh">
                                        <div>
                                            <img src={Icon1} className="pic-icon" alt="" />
                                            <p className=" mb-2">Coupons</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-s-m-4 col px-2 text-center">
                                <Link to="/weeklyad">
                                    <div className="b0x6font border rounded-lg text-secondary-light-gray bg-light btn-ligh">
                                        <div>
                                            <img src={Icon2} className="pic-icon" alt="" />
                                            <p className=" mb-2">Weekly Ad</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="  col-sm-4 col px-2 text-center">
                                <Link to='/Location'>
                                    <div className="b0x6font border rounded-lg text-secondary-light-gray bg-light btn-ligh">
                                        <div>
                                            <img src={Icon3} className="pic-icon" alt="" />
                                            <p className=" mb-2">Locations</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-3 btn-card-sm-s1 px-2">
                            <div className="  col-sm-4 col px-2 text-center">
                                <Link to="/profile">
                                    <div className="b0x6font border rounded-lg text-secondary-light-gray bg-light btn-ligh">
                                        <div>
                                            <img src={Icon4} className="pic-icon" alt="" />
                                            <p className=" mb-2">About Us</p>
                                        </div>
                                    </div>

                                </Link>
                            </div>
                            <div className="  col-sm-4 col px-2 text-center">
                                <Link to='/Reaward'>
                                    <div className="b0x6font border rounded-lg text-secondary-light-gray bg-light btn-ligh">
                                        <div>
                                            <img src={Icon5} className="pic-icon" alt="" />
                                            <p className=" mb-2">Rewards</p>
                                        </div>
                                    </div>

                                </Link>
                            </div>
                            <div className="  col-sm-4 col px-2 text-center">
                                <Link to="/ContactUs">
                                    <div className="b0x6font border rounded-lg text-secondary-light-gray bg-light btn-ligh">
                                        <div>
                                            <img src={Icon6} className="pic-icon" alt="" />
                                            <p className=" mb-2">Contact Us</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>


                    <div className=" p-4 mt-4 bg-white" style={{ position:"absolute", bottom:"0px", width:"100%", borderRadius: "20px 20px 0 0", boxShadow: "0 -0.3rem 1rem rgba(0,0,0,.15)" }}>
                        <div className="row mt-2 ">
                            <div className="col-sm-12 bottom-btm-font ">
                                <Link to="/Signin2"><button type="button" className="btn btn-success w-100 mt-3" style={{textTransform:"none"}}>Sign In</button></Link>
                                <Link to="/Register1"><button to="/Register1" type="button" className="btn btn-outline-success w-100 mt-3" style={{textTransform:"none"}} >New User</button></Link>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <Link to="/Termsandcondition" className="bottom-s1-link text-decoration-none" >Term and conditions</Link>
                            </div>
                            <div className="col">
                                <Link to="/Privacypolicy" className="bottom-s1-link text-decoration-none float-right" >Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signin1;