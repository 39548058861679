var data = 
{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              50.625,
              59.17592824927136
            ],
            [
              71.71875,
              59.17592824927136
            ],
            [
              71.71875,
              64.92354174306496
            ],
            [
              50.625,
              64.92354174306496
            ],
            [
              50.625,
              59.17592824927136
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              72.7734375,
              50.958426723359935
            ],
            [
              34.1015625,
              47.517200697839414
            ],
            [
              28.125,
              21.94304553343818
            ],
            [
              69.2578125,
              -2.460181181020993
            ],
            [
              91.40625,
              39.90973623453719
            ],
            [
              84.72656249999999,
              50.736455137010665
            ],
            [
              72.7734375,
              50.958426723359935
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            111.4453125,
            59.17592824927136
          ],
          [
            115.31249999999999,
            49.61070993807422
          ],
          [
            125.859375,
            60.58696734225869
          ],
          [
            111.09374999999999,
            65.2198939361321
          ],
          [
            92.10937499999999,
            73.02259157147301
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          138.8671875,
          65.36683689226321
        ]
      }
    }
  ]
}

export default data;