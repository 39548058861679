//Sir ki file hai yhe

import React, { useEffect,useState,useRef } from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import { Link , useHistory} from 'react-router-dom';
import Cardskeleton from '../pages/Skeleton';
import Cardskeleton2 from '../pages/Skeleton2';

import Webcam from 'react-webcam';

import Barside from '../assets/img/Icons/Icons/Icon-17.png'
import Image1 from '../assets/img/Icons/Icons/imageC1.png'
import gif from '../assets/img/image.gif'
import Image2 from '../assets/img/Icons/Icons/imageC2.png'
import Image3 from '../assets/img/Icons/Icons/imageC3.png'
import Image4 from '../assets/img/Icons/Icons/imageC4.png'

import Imageb1 from '../assets/img/Icons/Icons/bagde-hero.png'

import Imgcard1 from '../assets/img/Icons/Icons/cameraI.jpg'
import RedL from '../assets/img/Icons/Icons/sm-barcode.png'
import Detailofcoupon from './CoupensDetail';
import MainImgSmall from '../assets/img/Icons/Icons/smallimgicon.png'

import LPin from '../assets/img/Icons/Icons/Lpin.png'
import Footer from '../pages/footer'
import CoupensDetail from './CoupensDetail'
import Sidebar from './Sidebar';

import PullToRefresh from 'react-simple-pull-to-refresh';


const Demo = () => {


    return (
        <div style={{background:"#343a40"}}>
            
        </div>
    )
}

export default Demo;

